import debounce from 'lodash/debounce';
import { getJSON, setJSON } from '../util/storage';
import { gtmPushEvents } from '../helpers/gtmHelpers.js';

const modalBreakpoint = 768;
const sessionTimeoutSeconds = 1800;

/**
 * On window resize, updates wishlist add modal to either be centered or positioned at the bottom of the window
 */
const modalResizeHandler = debounce(() => {
    const $modalContainer = document.getElementById('addToWishlistModal');

    if (window.innerWidth < modalBreakpoint) {
        $modalContainer.classList.remove('modal__content');
        $modalContainer.classList.add('modal__bottom');
    } else {
        $modalContainer.classList.add('modal__content');
        $modalContainer.classList.remove('modal__bottom');
    }
}, 150);

/**
 * Starts spinner in product tile
 */
function startSpinner($tileImageContainer, $wishlistContainer) {
    $wishlistContainer.classList.add('actively-adding');
    $($tileImageContainer).spinner().start();
}

/**
 * Stops spinner in product tile
 */
function stopSpinner($tileImageContainer, $wishlistContainer) {
    $wishlistContainer.classList.remove('actively-adding');
    $($tileImageContainer).spinner().stop();
}

/**
 * Sets attributes on wishlist button when it has been added
 */
function setButtonAsAdded($button) {
    const $wishlistContainer = $button.closest('.js-wishlist-button-container');

    $wishlistContainer.classList.add('added', 'js-added');
    $button.setAttribute('aria-label', window.patagonia.clientDataModel.wishlist.ariaLabelRemove);
    $button.removeAttribute('data-tealium-event');

    if ($button.getAttribute('data-tealium-data')) {
        $button.setAttribute(
            'data-wishlist-tealium-data',
            $button.getAttribute('data-tealium-data')
        );
    }
    $button.removeAttribute('data-tealium-data');

    if ($button.classList.contains('js-wishlist-text-button')) {
        const $buttonSpan = $button.querySelector('span');
        if ($buttonSpan) {
            $buttonSpan.textContent = window.patagonia.clientDataModel.wishlist.labelRemove;
        }
    }
}

/**
 * Sets attributes on wishlist button when it is not added
 */
function unsetButtonAsAdded($button) {
    const $wishlistContainer = $button.closest('.js-wishlist-button-container');

    $wishlistContainer.classList.remove('added', 'js-added');
    $button.setAttribute('aria-label', window.patagonia.clientDataModel.wishlist.ariaLabelAdd);
    $button.setAttribute('data-tealium-event', $button.dataset.tealiumAddEvent);

    if ($button.getAttribute('data-wishlist-tealium-data')) {
        $button.setAttribute(
            'data-tealium-data',
            $button.getAttribute('data-wishlist-tealium-data')
        );
    }
    $button.removeAttribute('data-wishlist-tealium-data');

    if ($button.classList.contains('js-wishlist-text-button')) {
        const $buttonSpan = $button.querySelector('span');

        if ($buttonSpan) {
            $buttonSpan.textContent = window.patagonia.clientDataModel.wishlist.labelAdd;
        }
    }
}

/**
 * Adds a product to the wishlist
 */
function addToWishlist($button) {
    const addURL = window.patagonia.clientDataModel.wishlist.urlAdd;
    const { pid } = $button.dataset;
    if (!addURL || !pid) return;

    const url = new URL(addURL);
    url.searchParams.set('pid', pid);

    const $tile = $button.closest('.product-tile');
    const $tileImageContainer = $tile
        ? $tile.querySelector('.js-product-tile__image-container')
        : null;
    const $wishlistContainer = $button.closest('.js-wishlist-button-container');

    if ($tile) {
        startSpinner($tileImageContainer, $wishlistContainer);
    } else {
        $button.classList.add('btn-loading');
        $button.setAttribute('disabled', '');
    }

    fetch(url.toString(), { method: 'POST' })
        .then((response) => response.json())
        .then((data) => {
            const parser = new DOMParser();
            const $htmlResponse = parser.parseFromString(data.renderedTemplate, 'text/html');
            const $modal = $htmlResponse.querySelector('.modal');
            const $pageWrapper = document.querySelector('.page-wrapper');

            if (window.innerWidth < modalBreakpoint) {
                $modal.classList.remove('modal__content');
                $modal.classList.add('modal__bottom');
            } else {
                $modal.classList.add('modal__content');
                $modal.classList.remove('modal__bottom');
            }

            $pageWrapper.appendChild($modal);

            window.addEventListener('resize', modalResizeHandler);
            $modal.addEventListener(
                'hide.bs.modal',
                () => {
                    window.removeEventListener('resize', modalResizeHandler);
                    $modal.remove();
                },
                {
                    once: true,
                }
            );

            const confirmationModal = window.bootstrap.Modal.getOrCreateInstance($modal);
            confirmationModal.show();

            if (data.added || data.alreadyExistsInList) {
                setButtonAsAdded($button);
            }

            if (data.products) {
                setJSON('wishlistProducts', data.products, sessionTimeoutSeconds);
            }

            if (data.gtm) {
                gtmPushEvents(data.gtm);
            }
        })
        .catch((error) => {
            console.error('Error adding to wishlist: ', error);
        })
        .finally(() => {
            if ($tile) {
                stopSpinner($tileImageContainer, $wishlistContainer);
            } else {
                $button.classList.remove('btn-loading');
                $button.removeAttribute('disabled');
            }
        });
}

/**
 * Removes a product from the wishlist
 */
function removeFromWishlist($button) {
    const removeURL = window.patagonia.clientDataModel.wishlist.urlRemove;
    const { originalPid, pid } = $button.dataset;
    if (!removeURL || !pid) return;

    const $tile = $button.closest('.product-tile');
    const $tileImageContainer = $tile
        ? $tile.querySelector('.js-product-tile__image-container')
        : null;
    const $wishlistContainer = $button.closest('.js-wishlist-button-container');
    const onWishlistPage = !!$button.closest('.js-wishlist-grid');

    const url = new URL(removeURL);
    url.searchParams.set('pid', onWishlistPage ? originalPid : pid);

    if ($tile) {
        startSpinner($tileImageContainer, $wishlistContainer);
    } else {
        $button.classList.add('btn-loading');
        $button.setAttribute('disabled', '');
    }

    fetch(url.toString(), { method: 'POST' })
        .then((response) => response.json())
        .then((data) => {
            if (data.removed) {
                if (onWishlistPage) {
                    $tile.closest('.product-tile__wrapper')?.remove();

                    document.dispatchEvent(
                        new CustomEvent('wishlistProductRemoved', {
                            detail: {
                                productCount: data.products ? data.products.length : null,
                            },
                        })
                    );
                } else {
                    unsetButtonAsAdded($button);
                }
            }

            if (data.products) {
                setJSON('wishlistProducts', data.products, sessionTimeoutSeconds);
            }
        })
        .catch((error) => {
            console.error('Error removing from wishlist: ', error);
        })
        .finally(() => {
            if ($tile) {
                stopSpinner($tileImageContainer, $wishlistContainer);
            } else {
                $button.classList.remove('btn-loading');
                $button.removeAttribute('disabled');
            }
        });
}

/**
 * Updates product tiles to indicate if they have been added to a wishlist
 */
function updateProductTileWishlistButtonStates(products) {
    if (products && products.length > 0) {
        document.querySelectorAll('.js-wishlist-button').forEach(($button) => {
            if (products.includes($button.dataset.pid)) {
                setButtonAsAdded($button);
            }
        });
    }
}

/**
 * Gets array of wishlist product IDs
 */
function getWishlistProductIDs() {
    // Make sure wishlist exists before using it
    if (window.patagonia?.clientDataModel?.wishlist?.urlGetProductIDs) {
        try {
            fetch(window.patagonia.clientDataModel.wishlist.urlGetProductIDs)
                .then((response) => response.json())
                .then((data) => {
                    setJSON('wishlistProducts', data.products, sessionTimeoutSeconds);
                    updateProductTileWishlistButtonStates(data.products);
                })
                .catch((error) => {
                    console.error('Error getting wishlist product IDs: ', error);
                });
        } catch (error) {
            console.error('Error getting wishlist product IDs: ', error);
        }
    }
}

/**
 * Initializes wishlist product tile handling
 */
function initHandling() {
    if (window.patagonia.wishlistsInitialized) {
        return;
    }

    const initialWishlistProducts = getJSON('wishlistProducts');

    if (!initialWishlistProducts) {
        getWishlistProductIDs();
    } else {
        updateProductTileWishlistButtonStates(initialWishlistProducts);
    }

    // Wishlist button state update listener
    document.addEventListener('updateWishlistButtonStates', () => {
        updateProductTileWishlistButtonStates(getJSON('wishlistProducts'));
    });

    // Remove wishlistProducts from local storage upon login and logout
    ['customer-login', 'customer-logout'].forEach((event) => {
        document.addEventListener(event, () => {
            window.localStorage.removeItem('wishlistProducts');
        });
    });

    // Wishlist add/remove event listener
    document.addEventListener('click', (event) => {
        const $button = event.target.closest('.js-wishlist-button');
        if (!$button) return;

        const $buttonContainer = $button.closest('.js-wishlist-button-container');

        if ($buttonContainer.classList.contains('js-added')) {
            removeFromWishlist($button);
        } else {
            addToWishlist($button);
        }
    });

    // Event to update product tile variation group PID / the tile wishlist button state
    document.addEventListener('updateWishlistPID', (e) => {
        const { tealiumData, tile, variationGroupID } = e.detail;
        const $button = tile.querySelector('.js-wishlist-button');

        // Make sure button exists before using it
        if ($button) {
            // Update button PID based on newly selected variation group
            if (variationGroupID) {
                $button.dataset.pid = variationGroupID;
            }

            // Update wishlist button state
            const products = getJSON('wishlistProducts');

            if (products && products.includes($button.dataset.pid)) {
                $button.removeAttribute('data-tealium-data');

                if (tealiumData) {
                    $button.setAttribute('data-wishlist-tealium-data', JSON.stringify(tealiumData));
                }

                setButtonAsAdded($button);
            } else {
                $button.removeAttribute('data-wishlist-tealium-data');

                if (tealiumData) {
                    $button.setAttribute('data-tealium-data', JSON.stringify(tealiumData));
                }

                unsetButtonAsAdded($button);
            }
        }
    });

    window.patagonia.wishlistsInitialized = true;
}

export { initHandling };
export default initHandling;
