import { getBreakPoint } from '../util';
import { init as agePopup } from './agePopup.js';
import { gtmPushEvents } from '../helpers/gtmHelpers.js';
import { blrTrackEvent } from '../../../../../../int_bloomreach_patagonia/cartridge/client/default/js/helpers/blrHelpers';

let tealium = null;
let blrEnabled = false;

/**
 * Initializes Quick Add form buttons to add PID to input & trigger Add to Cart
 */
function initSizeSelection() {
    $('.quickadd-form .js-attr-value').on('click touchstart', (e) => {
        const $eventTarget = $(e.target);
        const $quickaddForm = $eventTarget.closest('.quickadd-form');
        const $quickAddBtn = $quickaddForm.find('.quickadd-atb-btn');
        const pid = $eventTarget.attr('data-pid');

        if (pid && !$eventTarget.hasClass('is-disabled')) {
            $quickaddForm.find('input[name="pid"]').val(pid);
            $quickaddForm.find('button.js-attr-value').attr('disabled', '');
            $eventTarget.addClass('btn-loading');

            $quickAddBtn.trigger('click');
        }
    });
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {string} event - triggered the event
 */
function showQuickAddSizes(selectedValueUrl, event) {
    // Update target if triggered by button or color swatch
    const eventTarget = event.target ? event.target : event;
    const modalQuickAdd =
        eventTarget.classList.contains('mobile-tile-quickadd-btn') ||
        eventTarget.closest('.product-comparison');

    eventTarget.classList.add('btn-loading');
    eventTarget.disabled = true;

    document.addEventListener(
        'quickAddSizesShown',
        () => {
            const $modal = document.getElementById('modalQuickAddSizes');

            if ($modal) {
                const modal = window.bootstrap.Modal.getOrCreateInstance($modal);
                if (modalQuickAdd) {
                    $modal.addEventListener('shown.bs.modal', () => {
                        initSizeSelection();
                    });

                    modal.show();
                } else {
                    $(eventTarget).parent().addClass('show-size-active');
                    $(eventTarget)
                        .siblings('.product-tile__quickadd-sizes')
                        .html($modal.querySelector('.js-product-quickadd-sizes-body'));

                    initSizeSelection();
                }
                eventTarget.classList.remove('btn-loading');
                eventTarget.disabled = false;

                // Keyboard Support
                document.dispatchEvent(new Event('initCustomInputs'));
                if (event.pointerType !== 'mouse') {
                    const validFormInputs = document.querySelectorAll(
                        '.js-attr-value:not([disabled])'
                    );
                    if (validFormInputs.length) {
                        validFormInputs[0].focus();
                    }
                }
            }
        },
        {
            once: true,
        }
    );

    document.dispatchEvent(
        new CustomEvent('showRemoteModal', {
            detail: {
                url: selectedValueUrl,
                successEvent: 'quickAddSizesShown',
                forceReload: true,
                doNotShow: true,
            },
        })
    );
}

/**
 * Initalizes product tile quick add events
 */
function showQuickAdd() {
    if (!tealium) {
        import('../app/tealium/tealium.js').then(({ default: tealiumLib }) => {
            tealium = tealiumLib;
        });
    }

    // Import Bloomreach Helpers
    blrEnabled = window?.patagonia?.clientDataModel?.bloomreach?.pixelEnabled;

    $(document).on('click', '.tile-quickadd', (e) => {
        e.preventDefault();

        const target = $(e.target);
        if (target.hasClass('is-singlesize') && !target.hasClass('disabled')) {
            target.addClass('btn-loading');
            target.attr('disabled', '');

            const pid = $(e.target).closest('.product').attr('data-pid');
            $(`#quickAddProduct-${pid} .quickadd-atb-btn`).trigger('click');
        } else {
            const selectedValueUrl = $(e.target).attr('data-url');
            showQuickAddSizes(selectedValueUrl, e);
        }
    });

    $(document).on('mouseleave blur', '.product-tile', (e) => {
        e.preventDefault();

        if (
            e.type.includes('focus') &&
            $(e.target)
                .closest('.product-tile')
                .find('.product-tile__quickadd-container')
                .hasClass('show-size-active')
        ) {
            return;
        }

        $('.show-size-active')
            .not('.keep-buttons')
            .removeClass('show-size-active')
            .find('.product-tile__quickadd-sizes')
            .empty();

        // Remove modal from DOM after it has been closed for desktop only
        // for mobile, modal will be removed on 'hidden.bs.modal' event
        const $modal = document.getElementById('modalQuickAddSizes');
        if ($modal && $modal?.style?.display?.length === 0) {
            $modal.remove();
        }
    });

    // Remove modal from page after it has been closed
    $(document).on('hidden.bs.modal', (e) => {
        const $target = $(e.target);
        if ($target.attr('id') === 'addToBagQuickAdd' || $target.hasClass('modal-quickadd-sizes')) {
            $target.remove();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button and displays ATC modal
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    const messageType = response.error ? 'alert-danger' : 'alert-success';

    // show add to cart modal
    if (
        ($('.product-tile__quickadd-sizes').length > 0 ||
            $('.product-tile__quickadd-one-size').length > 0) &&
        !response.error
    ) {
        if (!response.validPliCount) {
            document.dispatchEvent(
                new CustomEvent('showRemoteModal', {
                    detail: {
                        url: window?.patagonia?.clientDataModel?.modals?.maxQuantity,
                    },
                })
            );
        } else {
            // Update Nav QTY
            $('.modal-add-to-bag-button span').text(`(${response.quantityTotal})`);
            $('.navigation-primary__icon--cart_count').text(response.quantityTotal);

            // Add modal to DOM
            const $modalContainer = $(`<div>${response.renderedTemplate}</div>`);
            $('.page-wrapper').append($modalContainer);

            // Determine correct ATC Modal Position
            const $quickAddModal = document.getElementById('addToBagQuickAdd');
            if ($quickAddModal) {
                const $quickAddSizeModal = document.getElementById('modalQuickAddSizes');
                const isMobileSingleSize = document.querySelector(
                    '.product-tile__quickadd-container--mobile .btn-loading'
                );

                const forceDesktopAddToCart =
                    document.querySelector('.product-comparison') &&
                    getBreakPoint().includes('large');

                if ($quickAddSizeModal) {
                    if ($quickAddSizeModal.classList.contains('show') || isMobileSingleSize) {
                        $quickAddModal.classList.toggle('modal__bottom', !forceDesktopAddToCart);
                        $quickAddModal.classList.toggle('modal__content', forceDesktopAddToCart);

                        if (!isMobileSingleSize) {
                            const sizeModal =
                                window.bootstrap.Modal.getOrCreateInstance($quickAddSizeModal);
                            sizeModal.hide();
                        }
                    } else if ($quickAddModal.classList.contains('modal__bottom')) {
                        // Reset to default
                        $quickAddModal.classList.remove('modal__bottom');
                        $quickAddModal.classList.add('modal__content');
                    }
                }

                // Show Modal - Conditional prevents modal show/hide race condition
                const modal = new window.bootstrap.Modal($quickAddModal);
                if ($quickAddSizeModal && $quickAddSizeModal.classList.contains('show')) {
                    $quickAddSizeModal.addEventListener('hidden.bs.modal', () => {
                        modal.show();
                    });
                } else {
                    modal.show();
                }
            }

            // complete atc modal -> tabs component ser data ajax url once required params are known
            if ($('.modal-add-to-bag__info [data-ser-pid]')) {
                $('.modal-add-to-bag__info [data-ser-pid]').attr(
                    'data-ser-pid',
                    response.product.masterID
                );
            }
        }
    } else if (!$('main').attr('data-action') === 'Cart-Show') {
        // Don't show add to cart message on cart page
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(
            `<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${response.message}</div>`
        );

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Trigger ATC AJAX call on Quick Add size
 */
function addToCart() {
    $(document).on('submit', '.quickadd-form', (e) => {
        e.preventDefault();

        const $form = $(e.target);
        const formData = $form.serialize();
        const addToCartUrl = $form.attr('action');
        const $quickaddBtn = $form.find('button[type="submit"]');
        const $buttonLoading = $('button.btn-loading');

        if (agePopup($quickaddBtn)) {
            $('button.btn-loading').removeClass('btn-loading').removeAttr('disabled');
            return;
        }

        $(this).trigger('updateAddToCartFormData', formData);

        if (addToCartUrl) {
            if (!$buttonLoading) {
                $quickaddBtn.addClass('btn-loading').attr('disabled', '');
            }

            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: formData,
                success(data) {
                    if (data.error && data.errorModalURL) {
                        document.dispatchEvent(
                            new CustomEvent('showRemoteModal', {
                                detail: {
                                    url: data.errorModalURL,
                                    forceReload: true,
                                },
                            })
                        );
                        $(document).trigger('product:addToCartFailed', data);
                    } else {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);

                        import(/* webpackPrefetch: true */ '../bldm/ser.js').then(
                            ({ default: ser }) => {
                                ser.getSERData(ser.getSERProductData);
                            }
                        );

                        if (blrEnabled) {
                            const productData = {
                                prod_id: data.product.masterID,
                                sku: data.pid,
                            };

                            blrTrackEvent('cart', 'click-add', productData);
                        }

                        if (tealium && typeof tealium.collectCart === 'function') {
                            tealium.collectCart(
                                data,
                                data.pid,
                                data.product.selectedQuantity,
                                'cart_add'
                            );
                        }

                        if (data.gtm) {
                            gtmPushEvents(data.gtm);
                        }
                    }
                },
                error(data) {
                    if (data.error && data.errorModalURL) {
                        document.dispatchEvent(
                            new CustomEvent('showRemoteModal', {
                                detail: {
                                    url: data.errorModalURL,
                                    forceReload: true,
                                },
                            })
                        );
                    }

                    $(document).trigger('product:addToCartFailed', data);
                },
                complete() {
                    $buttonLoading.removeClass('btn-loading').removeAttr('disabled');
                },
            });
        }
    });
}

/**
 * General Quick Add Initialization
 */
export const init = function init() {
    const $main = document.querySelector('main');
    if (!$main.classList.contains('js-page-quickadd-initialized')) {
        showQuickAdd();
        addToCart();

        $main.classList.add('js-page-quickadd-initialized');
    }
};

export const methods = {
    showQuickAddSizes,
};

export default {
    init,
    methods,
};
